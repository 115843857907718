<template>
  <!-- View Dialog Component -->
  <v-dialog max-width="700" v-model="showViewVendorDialog" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-3"> View Vendor </v-card-title>

      <v-card-text class="mt-3">
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :value="vendor.unique_identifier"
                  label="Unique Identifier"
                  outlined
                  readonly
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :value="vendor.name"
                  label="Name"
                  outlined
                  readonly
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :value="vendor.email"
                  label="Email"
                  outlined
                  readonly
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :value="vendor.phone"
                  label="Phone"
                  outlined
                  readonly
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :value="vendor.state_name"
                  label="State"
                  outlined
                  readonly
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :value="vendor.city"
                  label="City"
                  outlined
                  readonly
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :value="vendor.address"
                  label="Address"
                  outlined
                  readonly
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :value="vendor.website"
                  label="Website"
                  outlined
                  readonly
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :value="vendor.low_margin"
                  label="Low Margin"
                  outlined
                  dense
                  hide-details
                  readonly
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :value="vendor.high_margin"
                  label="High Margin"
                  outlined
                  dense
                  hide-details
                  readonly
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleCloseViewDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "view-vendor-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    vendor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showViewVendorDialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.showViewVendorDialog = val;
    },
    showViewVendorDialog(val) {
      this.$emit("input", val);
      if (!val) {
        this.handleCloseViewDialog();
      }
    },
  },
  methods: {
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    handleCloseViewDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>
