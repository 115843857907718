<template>
    <!-- Delete Dialog Component -->
    <v-dialog v-model="showDeleteCategoryDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Delete Category
        </v-card-title>
  
        <v-card-text class="mt-3">
          Are you sure you want to delete Category {{ category.name }}?
        </v-card-text>
  
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteCategory(category)">Yes</v-btn>
          <v-btn text @click="$emit('close-dialog')">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "delete-inventory",
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      category: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        showDeleteCategoryDialog: this.value,
      };
    },
    watch: {
      value(val) {
        this.showDeleteCategoryDialog = val;
      },
      showDeleteCategoryDialog(val) {
        this.$emit("input", val);
        if (!val) {
          this.$emit("close-dialog");
        }
      },
    },
    methods: {
      async deleteCategory(item) {
        let alertData = {};
        let res = await this.$axios.delete(
          process.env.VUE_APP_API_URL + "/category/" + item.id
        );
  
        if (res.data.status) {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("reload-category");
        } else {
          this.alertText = res.data.message;
          this.alertType = "error";
        }
  
        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
      },
    },
  };
  </script>
  