<template>
  <div>
    <v-dialog max-width="700" persistent v-model="dialog">
      <v-card>
        <v-card-title class="headline grey lighten-3"> Add Item </v-card-title>

        <v-card-text class="mt-3">
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.upc"
                    label="UPC"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.wh_item"
                    label="WH Item"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.brand"
                    label="Brand"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.description"
                    label="Description"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.pack"
                    label="Pack"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.size"
                    label="Size"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.quantity"
                    label="Quantity"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.retail"
                    label="Retail"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.base_unit"
                    label="Base/Unit"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.delivery_cost_not_found"
                    label="Delivery Cost Not Found"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.gross_margin"
                    label="Gross Margin%"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.effective_qty"
                    label="Effective Qty"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.effective_price"
                    label="Effective Price"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.from_date"
                    label="From"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.to_date"
                    label="To"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.net_cost"
                    label="Net Cost"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.net_case"
                    label="Net Case"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.enhanced_dept"
                    label="Enhanced Department"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="item.category_id"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Select Category"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="item.department_id"
                    :items="departments"
                    item-text="name"
                    item-value="id"
                    label="Select Department"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.scan_dept"
                    label="Scan Department"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.upc_case"
                    label="UPC Case"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.retail_prior"
                    label="Prior Retail"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.tpr_priority"
                    label="TPR Priority"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.target_margin"
                    label="Target Margin%"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.loq"
                    label="LOQ"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.vendor"
                    label="Vendor"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.vendor_item"
                    label="Vendor Item"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.base_case"
                    label="Base/Case"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.base_margin"
                    label="Base Margin%"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.excise_tax"
                    label="Excise Tax"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.tpr_qty"
                    label="TPR Qty"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.tpr"
                    label="TPR"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.adp_qty"
                    label="ADP Qty"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.tpr_qty"
                    label="ADP"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#8b1a29" @click="AddInventory(item)" class="text-white"
            >Save</v-btn
          >
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "add-inventory-dialog",

  data() {
    return {
      item: {},
      dialog: true,
      departments: [],
      categories: [],
    };
  },
  mounted() {
    this.getDepartments();
    this.getCategories();
  },
  methods: {
    async AddInventory() {
      let alertData = {};
      try {
        let res = await this.$axios.post(
          process.env.VUE_APP_API_URL + "/inventories/",
          this.item
        );

        if (res.data.status) {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("reload-inventory");
        } else {
          alertData.alertText = res.data.message;
          alertData.alertType = "error";
        }

        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
        this.dialog = false;
      } catch (error) {
        alertData.alertText = error.response.data.message;
        alertData.alertType = "error";
        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
        this.dialog = false;
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
    getDepartments() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/department")
        .then((res) => {
          this.departments = res.data.data;
        });
    },
    getCategories() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/category").then((res) => {
        this.categories = res.data.data;
      });
    },
  },
};
</script>
