<template>
    <!-- View Dialog Component -->
    <v-dialog max-width="700" v-model="showEditDepartmentDialog" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Update Department
        </v-card-title>
  
        <v-card-text class="mt-3"> 
          <v-form ref="form">
            <v-container>
              <v-row>

                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localDepartment.name"
                    label="Name"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localDepartment.code"
                    label="Code"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
  
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#8b1a29"
            @click="updateDepartment(localDepartment)"
            style="color: white"
            >Update</v-btn
          >
          <v-btn text @click="$emit('close-dialog')">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  
  export default {
    name: "view-department-dialog",
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      department: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        showEditDepartmentDialog: this.value,
        localDepartment: { ...this.department }
      };
    },
    watch: {
      value(val) {
        this.showEditDepartmentDialog = val;
      },
      showEditDepartmentDialog(val) {
        this.$emit("input", val);
        if (!val) {
          this.handleCloseViewDialog();
        }
      },
    },
    methods: {
      async updateDepartment(item) {
        let alertData = {};
        let res = await this.$axios.put(
          process.env.VUE_APP_API_URL + "/department/" + item.id, item
        );
  
        if (res.data.status) {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("reload-department");
        } else {
          alertData.alertText = res.data.message;
          alertData.alertType = "error";
        }
  
        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
      },
      formattedDate(date) {
        let dateToFormat = new Date(date);
        return dateToFormat.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      },
      handleCloseViewDialog() {
        this.$emit("close-dialog");
      }
    }
  };
  </script>
  