<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      item-key="upc"
      class="elevation-1"
      :loading="loading"
      striped
      height="78vh"
      fixed-header
      :footer-props="footerProps"
      theme="dark"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-select
            v-model="selectedVendor"
            :items="vendors"
            item-text="name"
            item-value="id"
            label="Select Vendor"
            outlined
            dense
            @change="getInvoices"
          >
          <template #prepend-item>
            <v-text-field
              v-model="vendorSearch"
              label="Search Vendor"
              dense
              outlined
              class="px-4"
              @input="getVendors"
            ></v-text-field>
          </template>
          </v-select>

          <v-spacer></v-spacer>

          <!-- <v-btn
            @click="openFileDialog"
            class="button"
            :disabled="selectedVendor == null"
          >
            <v-icon left>mdi-cloud-upload</v-icon>
            Add Invoice
          </v-btn>

          <input
            type="file"
            ref="fileInput"
            style="display: none"
            accept="img/*, .png, .jpg, .jpeg, .pdf"
            @change="submitFile"
          /> -->

          <v-btn
            @click="showAddInvoiceDialog = true"
            class="button"
            :disabled="selectedVendor == null"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Invoice
          </v-btn>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="w40"
            outlined
            dense
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        <span>{{ formattedDate(item.created_at) }}</span>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <span>{{ formattedDate(item.date) }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="previewInvoice(item)">mdi-eye</v-icon>
            </template>
            <span>Preview</span>
          </v-tooltip>
        </span>
        <span class="ml-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="viewInvoiceDetail(item)"
                >mdi-arrow-top-right</v-icon
              >
            </template>
            <span>OCR</span>
          </v-tooltip>
        </span>
        <!-- <span class="ml-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="compareInvoiceDetail(item)"
                >mdi-compare</v-icon
              >
            </template>
            <span>Compare</span>
          </v-tooltip>
        </span> -->
        <span class="ml-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="downloadInvoice(item)"
                >mdi-download</v-icon
              >
            </template>
            <span>Download</span>
          </v-tooltip>
        </span>
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon class="text-red" v-on="on" @click="deleteInvoice(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Delete</span>
          </v-tooltip>
        </span>
      </template>
    </v-data-table>

    <add-invoice-dialog
      v-if="showAddInvoiceDialog"
      @close-dialog="showAddInvoiceDialog = false"
      @reload-invoices="getInvoices"
      @show-alert="showAlertData"
      :vendor_id="selectedVendor"
    />

    <delete-invoice-dialog
      v-if="showDeleteInvoiceDialog"
      v-model="showDeleteInvoiceDialog"
      :invoiceItem="selectedInvoice"
      @close-dialog="showDeleteInvoiceDialog = false"
      @reload-invoices="getInvoices"
      @show-alert="showAlertData"
    />

    <preview-invoice-dialog
      v-if="showPreviewInvoiceDialog"
      v-model="showPreviewInvoiceDialog"
      :src="selectedInvoice.image_path"
      @close-dialog="showPreviewInvoiceDialog = false"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AddInvoiceDialog from "@/components/Invoices/AddInvoiceDialog";
import DeleteInvoiceDialog from "@/components/Invoices/DeleteInvoiceDialog";
import PreviewInvoiceDialog from "@/components/Invoices/PreviewInvoiceDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "invoice-listing",
  data() {
    return {
      vendorSearch: "",
      search: "",
      loading: false,
      itemsPerPage: 10,
      counter: 0,
      showAlert: false,
      alertData: {},
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        {
          text: "Name",
          value: "name",
          class: "table-header",
        },
        {
          text: "Reference Number",
          value: "reference",
          class: "table-header",
        },
        {
          text: "Invoice Date",
          value: "date",
          class: "table-header",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "14%",
        },
      ],

      csvHeaders: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        {
          text: "UPC",
          value: "upc",
          class: "table-header",
        },
        {
          text: "Description",
          value: "description",
          class: "table-header",
          width: "15%",
        },
        {
          text: "Delivery Date",
          value: "delivery_date",
          class: "table-header",
          width: "9%",
        },
        {
          text: "Size",
          value: "size",
          class: "table-header",
          width: "5%",
        },
        {
          text: "Pack",
          value: "pack",
          class: "table-header",
        },
        {
          text: "Quantity",
          value: "quantity",
          class: "table-header",
        },
        {
          text: "Quantity Received",
          value: "quantity_received",
          class: "table-header",
        },
        {
          text: "Case Price",
          value: "case_price",
          class: "table-header",
        },
        {
          text: "Extended Case Price",
          value: "extended_case_price",
          class: "table-header",
        },
        {
          text: "RET Price",
          value: "ret_price",
          class: "table-header",
        },
        {
          text: "RET Profit",
          value: "ret_profit",
          class: "table-header",
        },
        {
          text: "RET GP",
          value: "ret_gp",
          class: "table-header",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "8%",
        },
      ],

      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      csvItems: [],
      showAddInvoiceDialog: false,
      showDeleteInvoiceDialog: false,
      showPreviewInvoiceDialog: false,
      selectedInvoice: {},
      vendors: [],
      selectedVendor: null,
      file: null,
    };
  },
  components: {
    AddInvoiceDialog,
    AlertComponent,
    DeleteInvoiceDialog,
    PreviewInvoiceDialog,
  },
  mounted() {
    document.title = "Razcofoods | Invoices";
    this.getVendors();
  },
  methods: {
    async getInvoiceDetail(invoiceId) {
      this.csvItems = [];
      this.loading = true;
      await this.$axios
        .get(process.env.VUE_APP_API_URL + "/invoice/data/" + invoiceId)
        .then((res) => {
          if (res.data && res.data.status) {
            this.csvItems = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },

    async downloadInvoice(item) {
      console.log(item);
      await this.getInvoiceDetail(item.id);
      const csvContent = this.generateCSV();
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "invoice_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    generateCSV() {
      const header = this.csvHeaders
        .filter((h) => h.value !== "action")
        .map((h) => h.text)
        .join(",");

      const rows = this.csvItems.map((item) =>
        this.csvHeaders
          .filter((h) => h.value !== "action")
          .map((h) => this.formatCSVField(item[h.value]))
          .join(",")
      );

      return [header, ...rows].join("\n");
    },

    formatCSVField(value) {
      if (value === null || value === undefined) {
        return "";
      }
      const escapedValue = String(value).replace(/"/g, '""');
      return `"${escapedValue}"`;
    },

    openFileDialog() {
      this.$refs.fileInput.click();
    },

    submitFile(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.file = files[0];
      }
      if (!this.file) return;
      this.loading = true;
      const formData = new FormData();
      formData.append("vendor_id", this.selectedVendor);
      formData.append("image", this.file);

      this.$axios
        .post(process.env.VUE_APP_API_URL + "/invoices", formData)
        .then(() => {
          this.loading = false;
          this.getInvoices();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    async getInvoices() {
      this.items = [];
      this.loading = true;
      await this.$axios
        .get(process.env.VUE_APP_API_URL + "/invoices/" + this.selectedVendor)
        .then((res) => {
          if (res.data && res.data.status) {
            this.items = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    async getVendors() {
      this.loading = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/vendors?search=" + this.vendorSearch)
        .then((res) => {
          if (res.data && res.data.status) {
            this.vendors = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    previewInvoice(item) {
      this.selectedInvoice = item;
      this.showPreviewInvoiceDialog = true;
    },
    viewInvoiceDetail(item) {
      const url = "/invoice/" + item.id;
      window.open(url, "_blank");
    },
    deleteInvoice(item) {
      this.selectedInvoice = item;
      this.showDeleteInvoiceDialog = true;
    },
    compareInvoiceDetail(item) {
      const url = "/invoice/comparison/" + item.id;
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}

.v-select {
  margin-top: 24px;
  width: 50%;
  max-width: 20%;
}

.button {
  margin-right: 20px;
  background-color: #8b1a29 !important;
  color: #fff;
  float: right;
}
</style>
