import { render, staticRenderFns } from "./VendorListing.vue?vue&type=template&id=793d2138&scoped=true"
import script from "./VendorListing.vue?vue&type=script&lang=js"
export * from "./VendorListing.vue?vue&type=script&lang=js"
import style0 from "./VendorListing.vue?vue&type=style&index=0&id=793d2138&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793d2138",
  null
  
)

export default component.exports