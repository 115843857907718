<template>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        item-key="id"
        class="elevation-1"
        :loading="loading"
        striped
        height="78vh"
        fixed-header
        :footer-props="footerProps"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-btn
              color="#8b1a29"
              class="ml-2 text-white"
              @click="showAddDepartmentDialog = true"
            >
              <v-icon left>mdi-plus</v-icon>
              Add Department
            </v-btn>
  
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="w40"
              outlined
              dense
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:[`item.number`]="{ index }">
          <span>{{ index + 1 }}</span>
        </template>
  
        <template v-slot:[`item.name`]="{ item }">
          <span class="text-align-center">{{ item.name ? item.name : "-" }}</span>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          <span>{{
            item.created_at ? formattedDate(item.created_at) : "-"
          }}</span>
        </template>
  
        <template v-slot:[`item.action`]="{ item }">
          <span>
            <v-icon @click="viewDepartmentDetail(item)">mdi-eye</v-icon>
          </span>
          <span class="ml-1"
            ><v-icon @click="editDepartment(item)">mdi-pencil</v-icon></span
          >
          <span
            ><v-icon class="text-red" @click="deleteDepartment(item)"
              >mdi-delete</v-icon
            ></span
          >
        </template>
      </v-data-table>
  
      <add-department-dialog
        v-if="showAddDepartmentDialog"
        @close-dialog="showAddDepartmentDialog = false"
        @reload-department="getDepartment"
        @show-alert="showAlertData"
      />
  
      <view-department-dialog
        v-if="showViewDepartmentDialog"
        v-model="showViewDepartmentDialog"
        :department="selectedDepartment"
        @close-dialog="showViewDepartmentDialog = false"
      />
  
      <edit-department-dialog
        v-if="showEditDepartmentDialog"
        v-model="showEditDepartmentDialog"
        :department="selectedDepartment"
        @close-dialog="showEditDepartmentDialog = false"
        @reload-department="getDepartment"
        @show-alert="showAlertData"
      />
  
      <delete-department-dialog
        v-if="showDeleteDepartmentDialog"
        v-model="showDeleteDepartmentDialog"
        :department="selectedDepartment"
        @close-dialog="showDeleteDepartmentDialog = false"
        @reload-department="getDepartment"
        @show-alert="showAlertData"
      />
  
      <alert-component
        v-if="showAlert"
        v-model="showAlert"
        :data="alertData"
        @close-alert="showAlert = false"
      />
    </v-container>
  </template>
  
  <script>
  import AddDepartmentDialog from "@/components/Department/AddDepartmentDialog";
  import ViewDepartmentDialog from "@/components/Department/ViewDepartmentDialog";
  import EditDepartmentDialog from "@/components/Department/EditDepartmentDialog";
  import DeleteDepartmentDialog from "@/components/Department/DeleteDepartmentDialog";
  import AlertComponent from "@/components/common/AlertComponent";
  
  export default {
    name: "department-listing",
    data() {
      return {
        search: "",
        loading: false,
        itemsPerPage: 10,
        counter: 0,
        showAlert: false,
        alertData: {},
        headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "Name", value: "name", class: "table-header" },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
        },
      ],
        footerProps: {
          "items-per-page-options": [
            20,
            50,
            100,
            500,
            { text: "All", value: -1 },
          ],
        },
        items: [],
        showAddDepartmentDialog: false,
        showViewDepartmentDialog: false,
        showEditDepartmentDialog: false,
        showDeleteDepartmentDialog: false,
        selectedDepartment: {}
      };
    },
    components: {
      AddDepartmentDialog,
      ViewDepartmentDialog,
      EditDepartmentDialog,
      AlertComponent,
      DeleteDepartmentDialog
    },
    mounted() {
      document.title = "Razcofoods | Departments";
      this.getDepartment();
    },
    methods: { 
      async getDepartment() {
        this.loading = true;
        let items = await this.$axios.get(
          process.env.VUE_APP_API_URL + "/department"
        );
        if (items.data && items.data.status) {
          this.items = items.data.data;
        }
        this.loading = false;
      },
      formattedDate(date) {
        let dateToFormat = new Date(date);
        return dateToFormat.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      },
      showAlertData(alertData) {
        this.alertData = alertData;
        this.showAlert = true;
      },
      viewDepartmentDetail(item) {
        this.selectedDepartment = item;
        this.showViewDepartmentDialog = true;
      },
      editDepartment(item) {
        this.selectedDepartment = item;
        this.showEditDepartmentDialog = true;
      },
      deleteDepartment(item) {
        this.selectedDepartment = item;
        this.showDeleteDepartmentDialog = true;
      },
    },
  };
  </script>
  
  <style scoped>
  .v-data-table-header {
    font-weight: bold;
  }
  
  .w40 {
    max-width: 220px;
  }
  
  .text-align-center {
    text-align: center !important;
  }
  
  .margin-left-3 {
    margin-left: -3px;
  }
  
  .padding-top-20 {
    padding-top: 20px;
  }
  
  .table-header {
    background: #8b1a29 !important;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: bold !important;
    text-align: center !important;
  }
  
  .theme--light.v-data-table
    .v-data-table-header
    th.sortable
    .v-data-table-header__icon {
    color: #fff !important;
  }
  
  .v-application .primary--text {
    color: #8b1a29 !important;
  }
  
  .v-application--is-ltr .v-text-field .v-label {
    color: #8b1a29 !important;
  }
  
  .text-red {
    color: red;
  }
  
  .text-white {
    color: white;
  }
  </style>
  