<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      striped
      height="78vh"
      fixed-header
      :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn
            color="#8b1a29"
            class="ml-2 text-white"
            @click="showAddCategoryDialog = true"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Category
          </v-btn>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="w40"
            outlined
            dense
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.number`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <span class="text-align-center">{{ item.name ? item.name : "-" }}</span>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        <span>{{
          item.created_at ? formattedDate(item.created_at) : "-"
        }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <span>
          <v-icon @click="viewCategoryDetail(item)">mdi-eye</v-icon>
        </span>
        <span class="ml-1"
          ><v-icon @click="editCategory(item)">mdi-pencil</v-icon></span
        >
        <span
          ><v-icon class="text-red" @click="deleteCategory(item)"
            >mdi-delete</v-icon
          ></span
        >
      </template>
    </v-data-table>

    <add-category-dialog
      v-if="showAddCategoryDialog"
      @close-dialog="showAddCategoryDialog = false"
      @reload-category="getCategory"
      @show-alert="showAlertData"
    />

    <view-category-dialog
      v-if="showViewCategoryDialog"
      v-model="showViewCategoryDialog"
      :category="selectedCategory"
      @close-dialog="showViewCategoryDialog = false"
    />

    <edit-category-dialog
      v-if="showEditCategoryDialog"
      v-model="showEditCategoryDialog"
      :category="selectedCategory"
      @close-dialog="showEditCategoryDialog = false"
      @reload-category="getCategory"
      @show-alert="showAlertData"
    />

    <delete-category-dialog
      v-if="showDeleteCategoryDialog"
      v-model="showDeleteCategoryDialog"
      :category="selectedCategory"
      @close-dialog="showDeleteCategoryDialog = false"
      @reload-category="getCategory"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AddCategoryDialog from "@/components/Category/AddCategoryDialog";
import ViewCategoryDialog from "@/components/Category/ViewCategoryDialog";
import EditCategoryDialog from "@/components/Category/EditCategoryDialog";
import DeleteCategoryDialog from "@/components/Category/DeleteCategoryDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "category-listing",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 10,
      counter: 0,
      showAlert: false,
      alertData: {},
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "Name", value: "name", class: "table-header" },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
        },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      showAddCategoryDialog: false,
      showViewCategoryDialog: false,
      showEditCategoryDialog: false,
      showDeleteCategoryDialog: false,
      selectedCategory: {},
    };
  },
  components: {
    AddCategoryDialog,
    ViewCategoryDialog,
    EditCategoryDialog,
    AlertComponent,
    DeleteCategoryDialog,
  },
  mounted() {
    document.title = "Razcofoods | Categories";
    this.getCategory();
  },
  methods: {
    async getCategory() {
      this.loading = true;
      let items = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/category"
      );
      if (items.data && items.data.status) {
        this.items = items.data.data;
      }
      this.loading = false;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewCategoryDetail(item) {
      this.selectedCategory = item;
      this.showViewCategoryDialog = true;
    },
    editCategory(item) {
      this.selectedCategory = item;
      this.showEditCategoryDialog = true;
    },
    deleteCategory(item) {
      this.selectedCategory = item;
      this.showDeleteCategoryDialog = true;
    },
  },
};
</script>

<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}
</style>
