<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      item-key="unique_identifier"
      class="elevation-1"
      :loading="loading"
      striped
      height="78vh"
      fixed-header
      :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn
            color="#8b1a29"
            class="ml-2 text-white"
            @click="showAddVendorDialog = true"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Vendor
          </v-btn>

          <v-btn
            color="#8b1a29"
            class="ml-2 text-white"
            @click="openFileDialog"
          >
            <v-icon left>mdi-cloud-upload</v-icon>
            Upload Vendor
          </v-btn>

          <input
            type="file"
            ref="fileInputVendor"
            style="display: none"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="submitFile"
          />

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="w40"
            outlined
            dense
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.number`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <span class="text-align-center">{{ item.name ? item.name : "-" }}</span>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <span class="text-align-center">{{
          item.email ? item.email : "-"
        }}</span>
      </template>

      <template v-slot:[`item.website`]="{ item }">
        <span class="text-align-center">{{
          item.website ? item.website : "-"
        }}</span>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <span class="text-align-center">{{
          item.phone ? item.phone : "-"
        }}</span>
      </template>
      <template v-slot:[`item.address`]="{ item }">
        <span class="text-align-center">{{
          item.address ? item.address : "-"
        }}</span>
      </template>
      <template v-slot:[`item.state_name`]="{ item }">
        <span class="text-align-center">{{
          item.state_name ? item.state_name : "-"
        }}</span>
      </template>
      <template v-slot:[`item.city`]="{ item }">
        <span class="text-align-center">{{ item.city ? item.city : "-" }}</span>
      </template>
      <template v-slot:[`item.low_margin`]="{ item }">
        <span class="text-align-center">{{ item.low_margin ? item.low_margin : "-" }}</span>
      </template>
      <template v-slot:[`item.high_margin`]="{ item }">
        <span class="text-align-center">{{ item.high_margin ? item.high_margin : "-" }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span>{{
          item.created_at ? formattedDate(item.created_at) : "-"
        }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <span>
          <v-icon @click="viewVendorDetail(item)">mdi-eye</v-icon>
        </span>
        <span class="ml-1"
          ><v-icon @click="editVendor(item)">mdi-pencil</v-icon></span
        >
        <span
          ><v-icon class="text-red" @click="deleteVendor(item)"
            >mdi-delete</v-icon
          ></span
        >
      </template>
    </v-data-table>

    <add-vendor-dialog
      v-if="showAddVendorDialog"
      @close-dialog="showAddVendorDialog = false"
      @reload-inventory="getVendors"
      @show-alert="showAlertData"
    />

    <view-vendor-dialog
      v-if="showViewVendorDialog"
      v-model="showViewVendorDialog"
      :vendor="selectedVendor"
      @close-dialog="showViewVendorDialog = false"
    />

    <edit-vendor-dialog
      v-if="showEditVendorDialog"
      v-model="showEditVendorDialog"
      :vendor="selectedVendor"
      @close-dialog="showEditVendorDialog = false"
      @reload-vendors="getVendors"
      @show-alert="showAlertData"
    />

    <delete-vendor-dialog
      v-if="showDeleteVendorDialog"
      v-model="showDeleteVendorDialog"
      :vendor="selectedVendor"
      @close-dialog="showDeleteVendorDialog = false"
      @reload-vendors="getVendors"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AddVendorDialog from "@/components/Vendors/AddVendorDialog";
import ViewVendorDialog from "@/components/Vendors/ViewVendorDialog";
import EditVendorDialog from "@/components/Vendors/EditVendorDialog";
import DeleteVendorDialog from "@/components/Vendors/DeleteVendorDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "vendor-listing",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 10,
      counter: 0,
      showAlert: false,
      alertData: {},
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        {
          text: "Unique Identifier",
          value: "unique_identifier",
          class: "table-header"
        },
        { text: "Name", value: "name", class: "table-header" },
        { text: "Phone", value: "phone", class: "table-header" },
        {
          text: "Low Margin",
          value: "low_margin",
          class: "table-header",
        },
        {
          text: "High Margin",
          value: "high_margin",
          class: "table-header",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "10%",
        },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      showAddVendorDialog: false,
      showViewVendorDialog: false,
      showEditVendorDialog: false,
      showDeleteVendorDialog: false,
      selectedVendor: {},
      file: null,
    };
  },
  components: {
    AddVendorDialog,
    ViewVendorDialog,
    EditVendorDialog,
    AlertComponent,
    DeleteVendorDialog,
  },
  mounted() {
    document.title = "Razcofoods | Vendors";
    this.getVendors();
  },
  methods: {
    openFileDialog() {
      this.$refs.fileInputVendor.click();
    },

    submitFile(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.file = files[0];
      }
      if (!this.file) return;
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.file);

      this.$axios
        .post(process.env.VUE_APP_API_URL + "/vendors/upload", formData)
        .then(() => {
          this.loading = false;
          this.getVendors();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    async getVendors() {
      this.loading = true;
      let items = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/vendors"
      );
      if (items.data && items.data.status) {
        this.items = items.data.data;
      }
      this.loading = false;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewVendorDetail(item) {
      this.selectedVendor = item;
      this.showViewVendorDialog = true;
    },
    editVendor(item) {
      this.selectedVendor = item;
      this.showEditVendorDialog = true;
    },
    deleteVendor(item) {
      this.selectedVendor = item;
      this.showDeleteVendorDialog = true;
    },
  },
};
</script>

<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}
</style>
