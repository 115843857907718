<template>
  <div>
    <v-dialog max-width="700" persistent v-model="dialog">
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Add Vendor
        </v-card-title>

        <v-card-text class="mt-3">
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.unique_identifier"
                    label="Unique Identifier"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.name"
                    label="Name"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.email"
                    label="Email"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.phone"
                    label="Phone"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="item.state_id"
                    :items="states"
                    item-text="name"
                    item-value="id"
                    label="State"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.city"
                    label="City"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.address"
                    label="Address"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.website"
                    label="Website"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.low_margin"
                    label="Low Margin"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.high_margin"
                    label="High Margin"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#8b1a29" @click="AddVendor()" class="text-white"
            >Save</v-btn
          >
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "add-inventory-dialog",

  data() {
    return {
      item: {
        unique_identifier: "",
        name: "",
        email: "",
        phone: "",
        city: "",
        state_id: "",
        address: "",
        website: "",
        high_margin: "",
        low_margin: ""
      },
      dialog: true,
      states: [],
    };
  },
  methods: {
    async AddVendor() {
      let alertData = {};
      try {
        let res = await this.$axios.post(
          process.env.VUE_APP_API_URL + "/vendors",
          this.item
        );

        if (res.data.status) {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("reload-inventory");
        } else {
          alertData.alertText = res.data.message;
          alertData.alertType = "error";
        }

        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
        this.dialog = false;
      } catch (error) {
        alertData.alertText = error.response.data.message;
        alertData.alertType = "error";
        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
        this.dialog = false;
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
    async getStates() {
      let res = await this.$axios.get(process.env.VUE_APP_API_URL + "/states");
      if (res.data.status) {
        this.states = res.data.data;
      }
    },
  },
  mounted() {
    this.getStates();
  },
};
</script>
