<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        item-key="id"
        class="elevation-1"
        :loading="loading"
        striped
        height="78vh"
        fixed-header
        :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn
            color="#8b1a29"
            class="ml-2 text-white"
            @click="showAddUserDialog = true"
          >
            <v-icon left>mdi-plus</v-icon>
            Add User
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
            class="w40"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.number`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>
      <template v-slot:[`item.address`]="{ item }">
        <span class="text-align-center">{{
            item.address ? item.address : "-"
          }}</span>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <span class="text-align-center">{{
            item.phone ? item.phone : "-"
          }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span>{{ formattedDate(item.created_at) }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <span>
          <v-icon @click="viewUserDetail(item)">mdi-eye</v-icon>
        </span>
        <span class="ml-1"
          ><v-icon @click="editUser(item)">mdi-pencil</v-icon></span
        >
        <span
          ><v-icon class="text-red" @click="deleteUser(item)"
            >mdi-delete</v-icon
          ></span
        >
      </template>
    </v-data-table>

    <add-user-dialog
        v-if="showAddUserDialog"
        @close-dialog="showAddUserDialog = false"
        @reload-users="getUsers"
        @show-alert="showAlertData"
    />

    <view-User-dialog
      v-if="showViewUserDialog"
      v-model="showViewUserDialog"
      :user="selectedUser"
      @close-dialog="showViewUserDialog = false"
    />

    <edit-user-dialog
      v-if="showEditUserDialog"
      v-model="showEditUserDialog"
      :user="selectedUser"
      @close-dialog="showEditUserDialog = false"
      @reload-users="getUsers"
      @show-alert="showAlertData"
    />

    <delete-user-dialog
        v-if="showDeleteUserDialog"
        v-model="showDeleteUserDialog"
        :user="selectedUser"
        @close-dialog="showDeleteUserDialog = false"
        @reload-users="getUsers"
        @show-alert="showAlertData"
    />

    <alert-component
        v-if="showAlert"
        v-model="showAlert"
        :data="alertData"
        @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AddUserDialog from "@/components/Users/AddUserDialog";
import ViewUserDialog from "@/components/Users/ViewUserDialog";
import EditUserDialog from "@/components/Users/EditUserDialog";
import DeleteUserDialog from "@/components/Users/DeleteUserDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "user-listing",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 10,
      showAlert: false,
      alertData: {},
      headers: [
        { text: "Sr.#", value: "number", sortable: false, class: "table-header" },
        { text: "Name", value: "name", class: "table-header" },
        { text: "Email", value: "email", class: "table-header" },
        { text: "Phone", value: "phone", class: "table-header" },
        { text: "Address", value: "address", class: "table-header" },
        { text: "Added At", value: "created_at", class: "table-header", width: "12.5%" },
        { text: "Actions", value: "action", class: "table-header", width: "10%" },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      showAddUserDialog: false,
      showViewUserDialog: false,
      showEditUserDialog: false,
      showDeleteUserDialog: false,
      selectedUser: {},
    };
  },
  components: {
    AddUserDialog,
    ViewUserDialog,
    EditUserDialog,
    DeleteUserDialog,
    AlertComponent,
  },
  mounted() {
    document.title = "Razcofoods | Users";
    this.getUsers();
  },
  methods: {
    async getUsers() {
      this.loading = true;
      let items = await this.$axios.get(process.env.VUE_APP_API_URL + "/users");
      if (items.data && items.data.status) {
        this.items = items.data.data;
      }
      this.loading = false;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewUserDetail(item) {
      this.selectedUser = item;
      this.showViewUserDialog = true;
    },
    editUser(item) {
      this.selectedUser = item;
      this.showEditUserDialog = true;
    },
    deleteUser(item) {
      this.selectedUser = item;
      this.showDeleteUserDialog = true;
    }
  },
};
</script>

<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #fff !important;
}
.v-application .primary--text {
  color: #8b1a29 !important;
}
.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}
.text-red {
  color: red;
}
.text-white {
  color: white;
}
</style>
