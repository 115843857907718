<template>
  <div class="fill-height">
    <v-row no-gutters class="fill-height">
      <!-- Left Side: Image -->
      <v-col cols="12" md="6" class="d-none d-md-flex p-0 m-0 fill-height">
        <v-img
          src="@/assets/grocer_login.avif"
          alt="Razcofoods"
          class="fill-height"
          style="object-fit: cover; height: 100%"
          gradient="to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)"
        ></v-img>
      </v-col>

      <!-- Right Side: Form -->
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center justify-center p-0 m-0 form-style"
      >
        <v-card class="mx-auto px-6 py-6 card-style">
          <v-card-title class="text-h4 font-weight-bold">
            <!-- Welcome to Razco Inc. Backoffice -->
            <div class="image">
              <img src="@/assets/razco-logo.webp" />
            </div>
          </v-card-title>
          <v-card-text class="mt-8">
            <v-form
              v-model="formValid"
              @submit.prevent="handleLogin"
              ref="loginForm"
            >
              <v-text-field
                v-model="email"
                label="Email Address"
                type="email"
                outlined
                required
                :rules="[rules.required, rules.email]"
                class="mb-4"
                dense
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                outlined
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="togglePasswordVisibility"
                :type="showPassword ? 'text' : 'password'"
                required
                :rules="[rules.required]"
                class="mb-4"
                dense
                hide-details
              ></v-text-field>

              <v-btn
                type="submit"
                color="#8b1a29"
                class="white--text mt"
                :disabled="!formValid || loading"
                block
              >
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  size="20"
                  color="white"
                ></v-progress-circular>
                <span v-else>Sign In</span>
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import AlertComponent from "@/components/common/AlertComponent.vue";

export default {
  name: "login-component",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      showAlert: false,
      loading: false,
      alertData: {},
      formValid: true,
      rules: {
        required: (value) => !!value || "This is a required field.",
        email: (value) => {
          const pattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
          return pattern.test(value) || "Please enter a valid email address.";
        },
      },
    };
  },
  components: {
    AlertComponent,
  },
  mounted() {
    if (localStorage.getItem("grocer_user")) {
      this.$router.push("/items");
    }
  },
  methods: {
    handleLogin() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        axios
          .post(process.env.VUE_APP_API_URL + "/login", {
            email: this.email,
            password: this.password,
          })
          .then((res) => {
            if (res.data.status) {
              this.loading = false;
              this.alertData = {
                alertType: "success",
                alertText: res.data.message,
              };
              this.$store.dispatch("login", res.data.data);
              localStorage.setItem(
                "grocer_user",
                JSON.stringify(res.data.data)
              );
              this.$router.push("/items");
            } else {
              this.loading = false;
              this.alertData = {
                alertType: "error",
                alertText: res.data.message,
              };
            }
            this.showAlert = true;
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data) {
              this.alertData = {
                alertType: "error",
                alertText:
                  err.response.data.message ||
                  "Something went wrong. Please try again.",
              };
              this.showAlert = true;
            }
          });
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.form-style {
  background-color: #fdf5f5;
  height: 100vh;
}

.card-style {
  width: 100%;
  max-width: 70%;
  height: 100%;
  max-height: fit-content;
}

.mt-button {
  margin-top: 100px;
}

.image {
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
}
</style>
