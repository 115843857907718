<template>
    <!-- View Dialog Component -->
    <v-dialog max-width="700" v-model="showViewDepartmentDialog" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-3"> View Department </v-card-title>
  
        <v-card-text class="mt-3">
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    :value="department.name"
                    label="Name"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    :value="department.code"
                    label="Code"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
  
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleCloseViewDialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "view-department-dialog",
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      department: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        showViewDepartmentDialog: this.value,
      };
    },
    watch: {
      value(val) {
        this.showViewDepartmentDialog = val;
      },
      showViewDepartmentDialog(val) {
        this.$emit("input", val);
        if (!val) {
          this.handleCloseViewDialog();
        }
      },
    },
    methods: {
      formattedDate(date) {
        let dateToFormat = new Date(date);
        return dateToFormat.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      },
      handleCloseViewDialog() {
        this.$emit("close-dialog");
      },
    },
  };
  </script>
  