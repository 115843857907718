<template>
    <v-dialog v-model="showPriceActionDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-3">
          <span class="text-capitalize"> {{ action }}  Price Change </span>
        </v-card-title>
  
        <v-card-text class="mt-3">
          Are you sure you want to {{action}} price change for UPC: {{ item.upc }}?
        </v-card-text>
  
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="applyAction(item)">Yes</v-btn>
          <v-btn text @click="$emit('close-dialog')">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "price-action-dialog",
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },
      action: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        showPriceActionDialog: this.value,
      };
    },
    watch: {
      value(val) {
        this.showPriceActionDialog = val;
      },
      showPriceActionDialog(val) {
        this.$emit("input", val);
        if (!val) {
          this.$emit("close-dialog");
        }
      },
    },
    methods: {
      async applyAction(item) {
        let alertData = {};
        let res = await this.$axios.post(
          process.env.VUE_APP_API_URL + "/price-action", {
            id: item.id,
            upc: item.upc,
            action: this.action
          }
        );
  
        if (res.data.status) {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("reload-listing");
        } else {
          this.alertText = res.data.message;
          this.alertType = "error";
        }
  
        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
      },
    },
  };
  </script>
  