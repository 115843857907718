<template>
    <v-container>
        <v-toolbar flat>
            <v-btn-toggle v-model="activeTable" mandatory>
                <v-btn value="new" @click="getPriceChangeItems">New</v-btn>
                <v-btn value="history" @click="getHistoryItems">History</v-btn>
            </v-btn-toggle>
            <v-spacer></v-spacer>

            <v-btn v-if="showDownloadButton" @click="downloadCSV" class="primary mr-2">
                <v-icon left>mdi-download</v-icon>
                Download BR CSV
            </v-btn>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details class="w40"
                outlined dense>
            </v-text-field>
        </v-toolbar>

        <v-data-table v-if="activeTable === 'new'" :headers="newTableHeaders" :items="items" :search="search"
            item-key="unique_identifier" class="elevation-1" :loading="loading" striped height="78vh" fixed-header
            :footer-props="footerProps">
            <template v-slot:[`item.number`]="{ index }">
                <span>{{ index + 1 }}</span>
            </template>
            <template v-slot:[`item.upc`]="{ item }">
                <span class="text-align-center">{{ item.upc ? item.upc : "-" }}</span>
            </template>
            <template v-slot:[`item.vendor`]="{ item }">
                <span class="text-align-center">{{
                    item.vendor.name ? item.vendor.name : "-"
                    }}</span>
            </template>
            <template v-slot:[`item.old_price`]="{ item }">
                <span class="text-align-center">{{
                    item.old_price ? item.old_price : "-"
                    }}</span>
            </template>
            <template v-slot:[`item.new_price`]="{ item }">
                <span class="text-align-center">{{
                    item.new_price ? item.new_price : "-"
                    }}</span>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
                <span>{{
                    item.created_at ? formattedDate(item.created_at) : "-"
                    }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <span><v-icon class="text-green"
                        @click="showConfirmationDialog(item, 'accept')">mdi-check-circle</v-icon></span>
                <span><v-icon class="text-red ml-2"
                        @click="showConfirmationDialog(item, 'reject')">mdi-close</v-icon></span>
            </template>
        </v-data-table>

        <v-data-table v-else :headers="historyTableHeaders" :items="historyItems" :search="search"
            item-key="unique_identifier" class="elevation-1" :loading="loading" striped height="78vh" fixed-header
            :footer-props="footerProps">
            <template v-slot:[`item.number`]="{ index }">
                <span>{{ index + 1 }}</span>
            </template>
            <template v-slot:[`item.upc`]="{ item }">
                <span class="text-align-center">{{ item.upc ? item.upc : "-" }}</span>
            </template>
            <template v-slot:[`item.vendor`]="{ item }">
                <span class="text-align-center">{{
                    item.vendor.name ? item.vendor.name : "-"
                    }}</span>
            </template>
            <template v-slot:[`item.user`]="{ item }">
                <span class="text-align-center">{{
                    item.user.name ? item.user.name : "-"
                    }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <v-chip v-if="item.status == 'reject'" color="secondary" variant="flat" class="pill text-white">
                    Rejected
                </v-chip>
                <v-chip color="green" class="pill text-white" variant="flat" v-else>
                    Accepted
                </v-chip>
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
                <span>{{
                    item.updated_at ? formattedDate(item.updated_at) : "-"
                    }}</span>
            </template>
            <template v-slot:[`item.old_price`]="{ item }">
                <span class="text-align-center">{{
                    item.old_price ? item.old_price : "-"
                    }}</span>
            </template>
            <template v-slot:[`item.new_price`]="{ item }">
                <span class="text-align-center">{{
                    item.new_price ? item.new_price : "-"
                    }}</span>
            </template>
        </v-data-table>

        <alert-component v-if="showAlert" v-model="showAlert" :data="alertData" @close-alert="showAlert = false" />
        <price-action-dialog v-if="showPriceActionDialog" v-model="showPriceActionDialog" :item="priceChangeItem"
            :action="priceChangeAction" @reload-listing="getPriceChangeItems" @show-alert="showAlertData"
            @close-dialog="showPriceActionDialog = false" />
    </v-container>
</template>

<script>
import AlertComponent from "@/components/common/AlertComponent";
import PriceActionDialog from "./PriceActionDialog";

export default {
    name: "vendor-listing",
    data() {
        return {
            search: "",
            loading: false,
            activeTable: "new", // Toggle between "new" and "history"
            items: [],
            historyItems: [],
            showAlert: false,
            showPriceActionDialog: false,
            alertData: {},
            priceChangeItem: {},
            showDownloadButton: false,
            priceChangeAction: "",
            newTableHeaders: [
                {
                    text: "Sr.#",
                    value: "number",
                    sortable: false,
                    class: "table-header",
                },
                { text: "UPC", value: "upc", class: "table-header" },
                { text: "Vendor", value: "vendor", class: "table-header" },
                {
                    text: "Old Per Unit Price",
                    value: "old_price",
                    class: "table-header",
                },
                {
                    text: "New Per Unit Price",
                    value: "new_price",
                    class: "table-header",
                },
                { text: "Added At", value: "created_at", class: "table-header" },
                {
                    text: "Actions",
                    value: "action",
                    class: "table-header",
                    width: "10%",
                },
            ],
            historyTableHeaders: [
                {
                    text: "Sr.#",
                    value: "number",
                    sortable: false,
                    class: "table-header",
                },
                { text: "UPC", value: "upc", class: "table-header" },
                { text: "Vendor", value: "vendor", class: "table-header" },
                {
                    text: "Old Per Unit Price",
                    value: "old_price",
                    class: "table-header",
                },
                {
                    text: "New Per Unit Price",
                    value: "new_price",
                    class: "table-header",
                },
                { text: "Status", value: "status", class: "table-header" },
                { text: "Performed By", value: "user", class: "table-header" },
                { text: "Updated At", value: "updated_at", class: "table-header" },
            ],
            footerProps: {
                "items-per-page-options": [
                    20,
                    50,
                    100,
                    500,
                    { text: "All", value: -1 },
                ],
            },
        };
    },
    components: {
        AlertComponent,
        PriceActionDialog,
    },
    mounted() {
        document.title = "Razcofoods | Price Change";
        this.getPriceChangeItems();
    },
    methods: {
        async getPriceChangeItems() {
            this.showDownloadButton = false;
            this.loading = true;
            let items = await this.$axios.get(
                process.env.VUE_APP_API_URL + "/price-change"
            );
            if (items.data && items.data.status) {
                this.items = items.data.data;
            }
            this.loading = false;
        },
        async getHistoryItems() {
            this.showDownloadButton = true;
            this.loading = true;
            let items = await this.$axios.get(
                process.env.VUE_APP_API_URL + "/price-change-history"
            );
            if (items.data && items.data.status) {
                this.historyItems = items.data.data;
            }
            this.loading = false;
        },
        formattedDate(date) {
            let dateToFormat = new Date(date);
            return dateToFormat.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });
        },
        showAlertData(alertData) {
            this.alertData = alertData;
            this.showAlert = true;
        },
        showConfirmationDialog(item, action) {
            this.priceChangeAction = action;
            this.priceChangeItem = item;
            this.showPriceActionDialog = true;
        },
        downloadCSV() {
            const csvContent = this.generateCSV();
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "history_br_data.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        generateCSV() {
            // Define the CSV header
            const header = ["UPC", "Old Price", "New Price"].join(",");

            // Filter and map through historyItems to extract the relevant fields
            const rows = this.historyItems
                .filter(item => item.status === "accept") // Include only items with status = "accept"
                .map(item => {
                    const upc = item.upc || "-";
                    const oldPrice = item.old_price || "-";
                    const newPrice = item.new_price || "-";
                    return [upc, oldPrice, newPrice].join(",");
                });

            // Join the header and rows to create the final CSV content
            return [header, ...rows].join("\n");
        }
    },
};
</script>

<style scoped>
.v-data-table-header {
    font-weight: bold;
}

.w40 {
    max-width: 220px;
}

.text-align-center {
    text-align: center !important;
}

.table-header {
    background: #8b1a29 !important;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: bold !important;
    text-align: center !important;
}

.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: #fff !important;
}

.text-red {
    color: red;
}

.text-green {
    color: green;
}

.v-btn--active {
    background-color: #8b1a29 !important;
    color: #fff !important;
}
</style>
