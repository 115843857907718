<template>
  <!-- View Dialog Component -->
  <v-dialog max-width="700" v-model="showEditVendorDialog" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-3">
        Update Vendor
      </v-card-title>

      <v-card-text class="mt-3"> 
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localVendor.unique_identifier"
                  label="Unique Identifier"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localVendor.name"
                  label="Name"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localVendor.email"
                  label="Email"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localVendor.phone"
                  label="Phone"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-select
                    v-model="localVendor.state_id"
                    :items="states"
                    item-text="name"
                    item-value="id"
                    label="State"
                    outlined
                    dense
                    hide-details
                  />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localVendor.city"
                  label="City"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localVendor.address"
                  label="Address"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localVendor.website"
                  label="Website"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localVendor.low_margin"
                  label="Low Margin"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localVendor.high_margin"
                  label="High Margin"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#8b1a29"
          @click="updateVendor(localVendor)"
          style="color: white"
          >Update</v-btn
        >
        <v-btn text @click="$emit('close-dialog')">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "view-vendor-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    vendor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEditVendorDialog: this.value,
      localVendor: { ...this.vendor },
      states: []
    };
  },
  watch: {
    value(val) {
      this.showEditVendorDialog = val;
    },
    showEditVendorDialog(val) {
      this.$emit("input", val);
      if (!val) {
        this.handleCloseViewDialog();
      }
    },
  },
  methods: {
    async updateVendor(item) {
      let alertData = {};
      let res = await this.$axios.put(
        process.env.VUE_APP_API_URL + "/vendors/" + item.id, item
      );

      if (res.data.status) {
        alertData.alertText = res.data.message;
        alertData.alertType = "success";
        this.$emit("reload-vendors");
      } else {
        alertData.alertText = res.data.message;
        alertData.alertType = "error";
      }

      this.$emit("show-alert", alertData);
      this.$emit("close-dialog");
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    handleCloseViewDialog() {
      this.$emit("close-dialog");
    },
    async getStates() {
        let res = await this.$axios.get(process.env.VUE_APP_API_URL + "/states");
        if(res.data.status) {
            this.states = res.data.data;
        }
    }
  },
  mounted() {
    this.getStates();
  }
};
</script>
