<template>
    <div>
      <v-dialog max-width="700" persistent v-model="dialog">
        <v-card>
          <v-card-title class="headline grey lighten-3">
            Add Department
          </v-card-title>
  
          <v-card-text class="mt-3">
            <v-form ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12" md="6" class="mb-3">
                    <v-text-field
                      v-model="item.name"
                      label="Name"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="mb-3">
                    <v-text-field
                      v-model="item.code"
                      label="Code"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
  
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#8b1a29" @click="AddDepartment()" class="text-white"
              >Save</v-btn
            >
            <v-btn text @click="closeDialog">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name: "add-department",
  
    data() {
      return {
        item: {
          name: "",
          code: "",
        },
        dialog: true,
        states: [],
      };
    },
    methods: {
      async AddDepartment() {
        let alertData = {};
        try {
          let res = await this.$axios.post(
            process.env.VUE_APP_API_URL + "/department",
            this.item
          );
  
          if (res.data.status) {
            alertData.alertText = res.data.message;
            alertData.alertType = "success";
            this.$emit("reload-department");
          } else {
            alertData.alertText = res.data.message;
            alertData.alertType = "error";
          }
  
          this.$emit("show-alert", alertData);
          this.$emit("close-dialog");
          this.dialog = false;
        } catch (error) {
          alertData.alertText = error.response.data.message;
          alertData.alertType = "error";
          this.$emit("show-alert", alertData);
          this.$emit("close-dialog");
          this.dialog = false;
        }
      },
      closeDialog() {
        this.$emit("close-dialog");
        this.dialog = false;
      }
    }
  };
  </script>
  