<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      item-key="upc"
      class="elevation-1"
      :loading="loading"
      striped
      height="78vh"
      fixed-header
      :footer-props="footerProps"
      theme="dark"
      :server-items-length="totalItems"
      :items-per-page="itemsPerPage"
      @update:options="updateTableOptions"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn
            color="#8b1a29"
            class="ml-2 text-white"
            @click="showAddInventoryDialog = true"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Item
          </v-btn>

          <file-upload
            @start-loader="loading = true"
            @stop-loader="loading = false"
            @reload-inventory="getInventory"
            @show-alert="showAlertData"
          ></file-upload>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="w40"
            outlined
            dense
            @input="getInventory()"
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item.upc"
            :class="getRowClass(item)"
          >
            <td>{{ index + 1 }}</td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.upc ? item.upc : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.vendor ? item.vendor : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item.description ? item.description : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.brand ? item.brand : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.wh_item ? item.wh_item : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.pack ? item.pack : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.size ? item.size : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.quantity ? item.quantity : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.retail ? item.retail : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.base_unit ? item.base_unit : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.gross_margin ? item.gross_margin : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.stock ? item.stock : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.effective_qty ? item.effective_qty : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.effective_price ? item.effective_price : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.from_date ? item.from_date : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.to_date ? item.to_date : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.net_cost ? item.net_cost : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.department.name ? item.department.name : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.category.name
                  ? item.category.name
                  : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.enhanced_dept ? item.enhanced_dept : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.scan_dept ? item.scan_dept : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.upc_case ? item.upc_case : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.retail_prior ? item.retail_prior : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.tpr_priority ? item.tpr_priority : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.target_margin ? item.target_margin : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.loq ? item.loq : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.vendor_item ? item.vendor_item : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.base_case ? item.base_case : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.base_margin ? item.base_margin : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.excise_tax ? item.excise_tax : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.tpr_qty ? item.tpr_qty : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.tpr ? item.tpr : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.adp_qty ? item.adp_qty : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.adp ? item.adp : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                formattedDate(item.created_at)
              }}</span>
            </td>
            <td>
              <span class="no-wrap">
                <span>
                  <v-icon @click="viewInventoryDetail(item)">mdi-eye</v-icon>
                </span>
                <span class="ml-1"
                  ><v-icon @click="editInventory(item)"
                    >mdi-pencil</v-icon
                  ></span
                >
                <span
                  ><v-icon class="text-red" @click="deleteInventory(item)"
                    >mdi-delete</v-icon
                  ></span
                >
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <add-inventory-dialog
      v-if="showAddInventoryDialog"
      @close-dialog="showAddInventoryDialog = false"
      @reload-inventory="getInventory"
      @show-alert="showAlertData"
    />

    <view-inventory-dialog
      v-if="showViewInventoryDialog"
      v-model="showViewInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showViewInventoryDialog = false"
    />

    <edit-inventory-dialog
      v-if="showEditInventoryDialog"
      v-model="showEditInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showEditInventoryDialog = false"
      @reload-inventory="getInventory"
      @show-alert="showAlertData"
    />

    <delete-inventory-dialog
      v-if="showDeleteInventoryDialog"
      v-model="showDeleteInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showDeleteInventoryDialog = false"
      @reload-inventory="getInventory"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
/* eslint-disable */
import FileUpload from "@/components/FileUpload/FileUpload";
import AddInventoryDialog from "@/components/Inventory/AddInventoryDialog";
import ViewInventoryDialog from "@/components/Inventory/ViewInventoryDialog";
import EditInventoryDialog from "@/components/Inventory/EditInventoryDialog";
import DeleteInventoryDialog from "@/components/Inventory/DeleteInventoryDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "listing-data",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 20,
      counter: 0,
      showAlert: false,
      alertData: {},
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "UPC", value: "upc", class: "table-header", sortable: true },
        {
          text: "Vendor",
          value: "vendor",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Description",
          value: "description",
          class: "table-header",
          width: "15%",
          sortable: true,
        },
        {
          text: "Brand",
          value: "brand",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "WH Item",
          value: "wh_item",
          class: "table-header",
          sortable: true,
        },
        { text: "Pack", value: "pack", class: "table-header", sortable: true },
        { text: "Size", value: "size", class: "table-header", sortable: true },
        {
          text: "Quantity",
          value: "quantity",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Retail",
          value: "retail",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Base Unit",
          value: "base_unit",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Gross Margin%",
          value: "gross_margin",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Stock",
          value: "stock",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Effective Qty",
          value: "effective_qty",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Effective Price",
          value: "effective_price",
          class: "table-header",
          sortable: true,
        },
        {
          text: "From Date",
          value: "from_date",
          class: "table-header",
          width: "10%",
          sortable: true,
        },
        {
          text: "To Date",
          value: "to_date",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "Net Cost",
          value: "net_cost",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Dept Description",
          value: "dept_description",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "Category Sub Description",
          value: "category_sub_description",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "Enhanced Dept",
          value: "enhanced_dept",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Scan Dept",
          value: "scan_dept",
          class: "table-header",
          width: "8%",
        },
        {
          text: "UPC Case",
          value: "upc_case",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "Retail Prior",
          value: "retail_prior",
          class: "table-header",
          width: "8%",
        },
        {
          text: "TPR Priority",
          value: "tpr_priority",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Target Margin%",
          value: "target_margin",
          class: "table-header",
          width: "8%",
        },
        {
          text: "LOQ",
          value: "loq",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Vendor Item",
          value: "vendor_item",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Base Case",
          value: "base_case",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Base Margin%",
          value: "base_margin",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Excise Tax",
          value: "excise_tax",
          class: "table-header",
          width: "8%",
        },
        {
          text: "TPR Qty",
          value: "tpr_qty",
          class: "table-header",
          width: "8%",
        },
        {
          text: "TPR",
          value: "tpr",
          class: "table-header",
          width: "8%",
        },
        {
          text: "ADP Qty",
          value: "ap_qty",
          class: "table-header",
          width: "8%",
        },
        {
          text: "ADP",
          value: "adp",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "10%",
        },
      ],
      footerProps: {
        "items-per-page-options": [20, 50, 100, 500],
      },
      items: [],
      showAddInventoryDialog: false,
      showViewInventoryDialog: false,
      showEditInventoryDialog: false,
      showDeleteInventoryDialog: false,
      selectedInventory: {},
      currentPage: 1,
      totalItems: 0,
      sortBy: "created_at",
      sortDesc: true,
    };
  },
  components: {
    FileUpload,
    AddInventoryDialog,
    ViewInventoryDialog,
    EditInventoryDialog,
    AlertComponent,
    DeleteInventoryDialog,
  },
  mounted() {
    document.title = "Razcofoods | Inventory";
    this.getInventory();
  },
  methods: {
    async getInventory() {
      this.loading = true;
      await this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/inventories?page=" +
            this.currentPage +
            "&per_page=" +
            this.itemsPerPage +
            "&search=" +
            this.search +
            "&sort_by=" +
            this.sortBy +
            "&sort_desc=" +
            this.sortDesc
        )

        .then((res) => {
          if (res.data && res.data.status) {
            this.items = res.data.data.data;
            this.totalItems = res.data.data.total;
          } else {
            this.items = res.data.data;
            this.totalItems = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    getRowClass(item) {
      if (item.retail <= 0) {
        return "zero-retail";
      }
      return "";
    },
    updateTableOptions({ page, itemsPerPage, sortBy, sortDesc }) {
      if (
        this.currentPage !== page ||
        this.itemsPerPage !== itemsPerPage ||
        this.sortBy !== sortBy ||
        this.sortDesc !== sortDesc
      ) {
        this.currentPage = page;
        this.itemsPerPage = itemsPerPage;
        this.sortBy = sortBy;
        this.sortDesc = sortDesc;
        this.getInventory();
      }
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewInventoryDetail(item) {
      this.selectedInventory = item;
      this.showViewInventoryDialog = true;
    },
    editInventory(item) {
      this.selectedInventory = item;
      this.showEditInventoryDialog = true;
    },
    deleteInventory(item) {
      this.selectedInventory = item;
      this.showDeleteInventoryDialog = true;
    },
  },
};
</script>

<style>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  text-wrap: nowrap !important;
  white-space: nowrap !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
  text-wrap: nowrap !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}

.no-wrap {
  white-space: nowrap;
}

.text-red {
  color: red !important;
}

.zero-retail {
  background-color: #95081b;
  color: white;
}

.zero-retail:hover {
  background-color: #bb0d24 !important;
}
</style>
